import React from "react"
import get from "lodash/get"
import Helmet from "react-helmet"
import styled from "styled-components"

import { graphql } from "gatsby"

import Layout from "../components/layout"

import Footer from "../components/footer"
import { Title } from "../components/Title"
import { ContentWrapper } from "../components/ContentWrapper"

const StudioPage = props => {
  const { content, title } = get(props, "data.datoCmsPrivacyPage", {})
  return (
    <Layout logo="black">
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <StyledGrid>
        <StyledTitle>{title}</StyledTitle>
        <StyledContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
      </StyledGrid>
      <Footer />
    </Layout>
  )
}

const StyledGrid = styled.div`
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 0 20px;
  max-width: 1100px;
  line-height: 1.5;

  @media (max-width: 992px) {
  }

  @media (max-width: 1300px) {
  }
`

const StyledTitle = styled(Title)`
  grid-row: 1;
  grid-column: 1/7;

  @media (min-width: 992px) {
    margin-bottom: 130px;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  grid-row: 3;
  grid-column: 2/5;

  margin-top: 70px;

  @media (min-width: 992px) {
    grid-row: 2;
    grid-column: 5;
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`

export const query = graphql`
  query {
    datoCmsPrivacyPage {
      title
      content
    }
  }
`

export default StudioPage
